import { MetaPropertyName } from 'vue-meta'
import { isObject, round } from 'lodash-es'
import { LocaleObject } from '@nuxtjs/i18n/types'
import ErrorLog from '@/lib/ErrorLog'
import { IHub } from '~/types/app/Hub'
import IProduct from '~/types/app/Product'
import {
  ALLOWED_PAYMENT_METHODS_PRODUCTION,
  ALLOWED_PAYMENT_METHODS_STAGING,
  COUNTRY_CURRENCIES,
  DEFAULT_HUB_SLUGS,
  NO_INDEX_LOCALES,
  ROUTE_LOCALE_SEPARATOR,
} from '~/lib/constants'
import { ECountries } from '~/types/app/Countries'
import VueI18n, { LocaleMessage } from 'vue-i18n'
import { ITranslationInput } from '~/types/app/I18n'
import IBreadcrumbItem from '~/types/app/Breadcrumb'
import { ILink } from '~/types/app/Links'
import { ILpLinkTileIcon } from '~/types/generated/contentful'
import { ICoordinates } from '~/types/app/Location'
import { ROUTES } from '~/lib/routes'
import VueRouter, { RawLocation, Route } from 'vue-router'
import { NuxtCookies } from 'cookie-universal-nuxt'
import { getUserCookie } from '~/plugins/user.server'
import { IPaymentReceipt } from '~/types/app/Cart'
import ReceiptTemplate from '~/components/Receipt/Receipt.vue'
import Vue from 'vue'
import { Base64 } from 'js-base64'
import { Sha256 } from '@aws-crypto/sha256-browser'
import type { AuthProvider } from '~/types/app/Account'
import { SOCIAL_LOGIN_PROVIDERS } from '~/types/app/Account'

export const getCountryFromLocale = (locale: string): string => {
  return locale.slice(3, 5) || ECountries.DE
}

export const fixAPILocale = (locale: string | false | undefined): string => {
  return locale === 'en' || !locale ? `en-${ECountries.DE}` : locale
}

export const timeStringToDate = (timeString?: string): Date | null => {
  if (!timeString) {
    return null
  }

  const [hours, minutes, seconds] = timeString.split(':')

  const date = new Date()
  date.setHours(hours ? +hours : 0)
  date.setMinutes(minutes ? +minutes : 0)
  date.setSeconds(seconds ? +seconds : 0)

  return date
}

export function linksArr(field: ILpLinkTileIcon[]): ILink[] {
  return field.map((link) => {
    return {
      linkUrl: link.fields.linkUrl,
      linkLabel: link.fields.linkLabel,
      linkIcon: link.fields.linkIcon?.fields.file.url,
    }
  })
}

export const localizeTimeString = (
  timeString?: string,
  withSeconds = false
): string => {
  const date = timeStringToDate(timeString)
  if (!date) {
    return ''
  }

  return localizeDate(date, withSeconds)
}

export const localizeDate = (date: Date, withSeconds = false): string => {
  return date
    .toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      ...(withSeconds && { second: 'numeric' }),
    })
    .replace(/(?:AM|PM)$/, (m) => m.toLowerCase())
}

export const getAllValidLocales = (
  locales: (string | LocaleObject)[] | undefined
): string[] => {
  if (locales === undefined) {
    new ErrorLog('Locales are undefined').log()
    return []
  } else if (
    locales.some((locale) => {
      return typeof locale === 'string' || typeof locale.code !== 'string'
    })
  ) {
    new ErrorLog(
      `All locales must be fully defined locale objects. Instead they are: ${JSON.stringify(
        locales
      )}`
    ).log()
  }
  const localeObjects = locales.filter(
    (locale) => typeof locale !== 'string' && typeof locale.code === 'string'
  ) as LocaleObject[]
  return localeObjects.map((locale) => locale.code)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasAllDefined = (list: any[]): boolean | Error => {
  if (!list) {
    throw new Error('A list must be provided.')
  }
  if (!list.length) {
    return false
  }
  return !list.some((value) => value === undefined || value === null)
}

export const voucherFieldValidation = (code: string): boolean =>
  code.length < 100 && code.length > 0 && /^[A-Za-z0-9\-_]*$/.test(code)

export const emailValidation = (email: string): boolean =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  )

export const passwordValidation = (password: string): boolean =>
  password.length > 5 && /\d/.test(password)

export const metaNoIndex = (locale = 'en'): MetaPropertyName[] => {
  const metaData: MetaPropertyName[] = []

  if (NO_INDEX_LOCALES.includes(locale)) {
    metaData.push({
      hid: 'robots',
      name: 'robots',
      content: 'noindex',
    })
  }

  return metaData
}

export const validateMarket = (locale: string, hub?: IHub): boolean => {
  const userCountry = getCountryFromLocale(locale)
  const hubCountry = hub?.country
  if (!hub || (!userCountry && isMarketDefaultHub(hub))) {
    return true
  }
  return userCountry === hubCountry
}

export const getCurrencyByLocale = (locale: string): string => {
  const country = getCountryFromLocale(locale) as ECountries
  return COUNTRY_CURRENCIES[country] || 'EUR'
}

export const prettifyPhoneNumberPrefix = (
  phoneNumber?: string
): string | undefined => {
  if (!phoneNumber) return phoneNumber
  // takes a phone number like 004912345 and returns +49 12345
  return phoneNumber
    .replace(/^(.{4})(.*)$/, '$1 $2')
    .replace(/^0+/, '+')
    .replace('  ', ' ')
}

export const getAllowedPaymentMethodsByLocale = (
  locale: string,
  isProd: boolean
): Array<string> => {
  const country = getCountryFromLocale(locale) as ECountries

  const paymentMethods = isProd
    ? ALLOWED_PAYMENT_METHODS_PRODUCTION
    : ALLOWED_PAYMENT_METHODS_STAGING

  return paymentMethods[country] || []
}

export const isMarketDefaultHub = (hub: IHub): boolean => {
  return Object.values(DEFAULT_HUB_SLUGS).includes(hub.slug)
}

export const calculateDeposit = (sum: number, p: IProduct): number => {
  return !p.deposit ? sum : sum + p.deposit.amount * p.quantity
}

export const translateIfNecessary = (
  message: string | ITranslationInput | undefined,
  t: VueI18n['t']
): LocaleMessage => {
  if (!message) {
    return ''
  }

  return typeof message === 'string' ? message : t(message.key, message.params)
}

export const extractErrorMessage = (error: any): string => {
  return error instanceof Error ? error.message : String(error)
}

export const textToPartiallyBoldHTML = (
  text = '',
  boldSubstring = ''
): string => {
  if (!boldSubstring) return text

  if (text.includes(boldSubstring)) {
    const segments = text.split(boldSubstring)
    return `<span>${segments[0]}</span><strong>${boldSubstring}</strong><span>${segments[1]}</span>`
  } else {
    return text
  }
}

export const getPrefixedMutation = (
  prefix: string,
  actionName: string
): string => `${prefix}/${actionName}`

export function getBreadcrumbItemPath(breadcrumbItem: IBreadcrumbItem): string {
  // Differentiates path output for list head, categories and sub-categories
  if (isObject(breadcrumbItem.path)) {
    if (breadcrumbItem.path.params?.category) {
      // Parent category path output
      return `/category/${breadcrumbItem.path?.params?.category}/${breadcrumbItem.path?.params?.slug}`
    } else if (breadcrumbItem.path?.params?.slug) {
      // Sub-category path output
      return `/category/${breadcrumbItem.path?.params?.slug}`
    } else {
      // Fallback to the Shop
      return `/shop`
    }
  } else {
    // List head path output, ensuring there are no double slashes
    return `/${breadcrumbItem.path}`.replaceAll('//', '/')
  }
}

export type IBreadcrumbItemListElement = {
  '@type': string
  position: number
  name: VueI18n.TranslateResult
  item: string
}

export type IBreadcrumbItemListLastElement = {
  '@type': string
  position: number
  name?: string
}

export interface IMetaInfoScriptBreadcrumb {
  '@context': 'https://schema.org/'
  '@type': 'BreadcrumbList'
  itemListElement: Array<
    IBreadcrumbItemListElement | IBreadcrumbItemListLastElement
  >
}

export function getStructuredBreadcrumbListData({
  breadcrumbItems,
  currentItemPosition,
  currentItemName,
}: {
  breadcrumbItems: IBreadcrumbItem[]
  currentItemPosition: number
  currentItemName?: string
}): IMetaInfoScriptBreadcrumb {
  return {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement: [
      ...breadcrumbItems.map(
        (breadcrumbItem: IBreadcrumbItem, index: number) => ({
          '@type': 'ListItem',
          position: index + 1,
          name: breadcrumbItem.name,
          item: `https://goflink.com${getBreadcrumbItemPath(breadcrumbItem)}`,
        })
      ),
      {
        '@type': 'ListItem',
        position: currentItemPosition,
        name: currentItemName,
      },
    ],
  }
}

export const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const centToEuro = (cent: number): number => {
  return round(cent / 100, 2)
}

export const getDeliveryTimeRange = (
  minimumOffsetMinutes: number,
  maximumOffsetMinutes: number,
  deliveryETANumber: number
): {
  start: string
  end: string
} => {
  const millisecondsInMinute = 60000
  const now = new Date()
  const exactEstimation = new Date(
    now.getTime() + deliveryETANumber * millisecondsInMinute
  )

  const start = new Date(
    exactEstimation.getTime() + minimumOffsetMinutes * millisecondsInMinute
  )
  const end = new Date(
    exactEstimation.getTime() + maximumOffsetMinutes * millisecondsInMinute
  )

  return {
    start: localizeDate(start),
    end: localizeDate(end),
  }
}

export const isDeliveryETAAboveOrEqualThreshold = (
  getDeliveryETANumber: number,
  threshold: number
): boolean => {
  return getDeliveryETANumber >= threshold
}

export const getTotalAmount = (products: IProduct[]): number => {
  return products.reduce(
    (sum: number, p: IProduct) => sum + p.price.amount * p.quantity,
    0
  )
}

export const roundToDecimal = (
  num: number,
  decimalPrecision?: number
): number => {
  if (decimalPrecision === undefined) return num

  const decimalMoveFactor = 10 ** decimalPrecision
  return Math.round(num * decimalMoveFactor) / decimalMoveFactor
}

export const areNumbersTheSame = (
  num1: number,
  num2: number,
  decimalPrecision?: number
): boolean => {
  const roundedNum1 = roundToDecimal(num1, decimalPrecision)
  const roundedNum2 = roundToDecimal(num2, decimalPrecision)

  return roundedNum1 === roundedNum2
}

export const areCoordinatesTheSame = (
  coords1: ICoordinates,
  coords2: ICoordinates,
  decimalPrecision?: number
): boolean => {
  return (
    areNumbersTheSame(coords1.latitude, coords2.latitude, decimalPrecision) &&
    areNumbersTheSame(coords1.longitude, coords2.longitude, decimalPrecision)
  )
}

export const incrementPatchVersion = (version: string): string => {
  if (!version) return ''

  const [major, minor, patch] = version.split('.')

  return `${major}.${minor}.${Number(patch) + 1}`
}

export const checkSkippedRoutes = (
  skippedRoutes: string[],
  router: VueRouter,
  routerHistory: string[],
  localePath: (route: RawLocation) => string
): void => {
  const prevRoute = routerHistory[routerHistory.length - 1]
  if (skippedRoutes.includes(prevRoute)) {
    const lastValidRoute = JSON.parse(JSON.stringify(routerHistory))
      .reverse()
      .find((route: string) => !skippedRoutes.includes(route))
    if (!lastValidRoute) return
    router.push(localePath(lastValidRoute))
  } else {
    router.push(localePath(prevRoute || ROUTES.HOME))
  }
}

export const hasLocalePrefix = (slug: string): boolean =>
  /^[a-z]{2,4}(-[A-Z][a-z]{3})?(-([A-Z]{2}|[0-9]{3}))?$/.test(slug)

export function getUserFlinkUID(cookies: NuxtCookies): string {
  const [flinkUid] = getUserCookie(cookies)

  return flinkUid
}

export function getRouteName(route: Route): string {
  const [routeName] = (route?.name ?? '').split(ROUTE_LOCALE_SEPARATOR)

  return routeName
}

export function convertReceiptToHTML(receipt: IPaymentReceipt): string {
  const Component = Vue.extend(ReceiptTemplate)
  const node = new Component({ propsData: { receipt } }).$mount()

  return Base64.encode(node.$el.outerHTML)
}

export async function sha256email(email: string | undefined): Promise<string> {
  if (!email) return ''

  const encoder = new TextEncoder()
  const emailEncoded = await window.crypto.subtle.digest(
    'SHA-256',
    encoder.encode(email.toLocaleLowerCase())
  )

  const hashArray = Array.from(new Uint8Array(emailEncoded))
  return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
}

export function applyPrefix(route: string): string {
  const prefix = process.env.SERVICE_PATH
    ? process.env.SERVICE_PATH.slice(0, -1)
    : ''

  return prefix + route
}

export function isSocialLoginProvider(provider?: AuthProvider): boolean {
  const availableProviders = Object.values(SOCIAL_LOGIN_PROVIDERS) as string[]
  return availableProviders.includes(provider ?? '')
}

export function getAccountAgeInWeeks(createdAt: string): number {
  if (isNaN(Date.parse(createdAt))) return 0

  const date = new Date(createdAt)
  const today = new Date()
  const diff = today.getTime() - date.getTime()

  return Math.floor(diff / (24 * 3600 * 1000 * 7))
}

export async function generateSHA256(message: string): Promise<string> {
  const hash = new Sha256()
  hash.update(message)
  const digest = await hash.digest()
  const hashArray = Array.from(new Uint8Array(digest))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}
