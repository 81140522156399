import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosError } from 'axios'
import { fixAPILocale } from '~/lib/helpers'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'

export default async function put<ResponseType = undefined>(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  url: string,
  headers: Record<string, string | false | undefined> = {},
  body?: unknown
): Promise<AxiosResponse<ResponseType>> {
  const { Authorization, ...otherHeaders } = headers as Record<string, string>

  Object.keys(otherHeaders).forEach((header) =>
    client.setHeader(
      header,
      header === 'locale'
        ? fixAPILocale(otherHeaders[header])
        : otherHeaders[header]
    )
  )

  try {
    const response = await client.put(
      url,
      body,
      Authorization ? { headers: { Authorization } } : undefined
    )

    logger({
      level: ELogLevels.INFO,
      msg: `PUT Call successful: ${url}`,
      'http.method': EHTTPMethods.PUT,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.API_CONTROLLERS,
      func: 'put',
      slug: 'api.put.success',
    })

    return response
  } catch (error) {
    const statusCode = (error as AxiosError).response?.status || EStatus.FAILED

    logger({
      level: ELogLevels.ERROR,
      msg: 'PUT Call failed',
      'http.method': EHTTPMethods.PUT,
      'http.url': url,
      'http.status_code': statusCode as string,
      error: error as string,
      logger: ELoggers.API_CONTROLLERS,
      func: 'put',
      slug: 'api.put.error',
    })

    throw error
  }
}
