/* eslint-disable camelcase */
import { AxiosError } from 'axios'
import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { post } from '~/api/controllers/post'
import {
  EHTTPMethods,
  ELoggers,
  ELogLevels,
  EStatus,
  LoggerFunction,
} from '~/types/app/Logger'
import type { SocialSignupParams } from './types'

interface IParameters extends SocialSignupParams {
  client: NuxtAxiosInstance
  logger: LoggerFunction
  locale: string
}

interface IBody {
  first_name: string
  last_name: string
  nonce: string
}

export interface SocialSignupResponse {
  success: boolean
  isExistingUser: boolean
  errorMessage?: string
}

export async function socialSignup({
  client,
  idToken,
  logger,
  locale,
  firstName: first_name,
  lastName: last_name,
  nonce,
}: IParameters): Promise<SocialSignupResponse> {
  const url = '/me/social-sign-up'
  const headers = { Authorization: `Bearer ${idToken}`, locale }
  const body: IBody = { first_name, last_name, nonce }
  const result: SocialSignupResponse = {
    success: false,
    isExistingUser: false,
    errorMessage: '',
  }

  try {
    const response = await post(client, logger, url, headers, body)
    result.success = true
    result.isExistingUser = response.status === 204
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error)
    result.errorMessage = errorMessage

    logger({
      level: ELogLevels.ERROR,
      msg: errorMessage,
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.FAILED,
      error: String((error as AxiosError).response?.data),
      logger: ELoggers.LOGIN,
      func: 'socialSignup',
    })
  }

  return result
}
