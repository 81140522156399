/* eslint-disable camelcase */
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signOut,
  setPersistence,
  confirmPasswordReset,
  verifyPasswordResetCode,
  browserLocalPersistence,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithCredential,
} from 'firebase/auth'
import { Plugin, Context } from '@nuxt/types'
import { OAuthProvider, onAuthStateChanged, User } from '@firebase/auth'
import {
  firebaseStagingConfig,
  firebaseProductionConfig,
} from '~/firebase.config'
import { createIntercomHash } from '~/api/services/create-intercom-hash'
import {
  socialSignup,
  SocialSignupResponse,
} from '~/api/services/account/social-signup'
import type { SocialLoginProvider } from '~/types/app/Account'
import { AUTH_PROVIDERS } from '~/types/app/Account'
import { isSocialLoginProvider } from '~/lib/helpers'
import { FireUser } from '~/store/account'
import type { SocialSignupParams } from '~/api/services/account/social-signup/types'
import { ELoggers, ELogLevels } from '~/types/app/Logger'

export interface SignInWithSocialProviderParams
  extends Omit<SocialSignupParams, 'nonce'> {
  provider: SocialLoginProvider
  rawNonce: string
  hashedNonce: string
}

export interface IFirebasePlugin {
  signOut: () => void
  signInWithEmailAndPassword: (email: string, password: string) => Promise<void>
  verifyPasswordResetCode: (outOfBandCode: string) => Promise<string>
  resetPassword: (outOfBandCode: string, newPassword: string) => Promise<void>
  signInWithSocialProvider: (
    params: SignInWithSocialProviderParams
  ) => Promise<SocialSignupResponse>
  currentUser: FireUser | null
}

async function onSuccessfulAuthStateChange({
  context,
  fireInstance,
  user,
  isExistingUser,
}: {
  context: Context
  fireInstance: IFirebasePlugin
  user: User | null
  isExistingUser?: boolean
}) {
  const { store, app, $axios, $logger, $cookies } = context

  fireInstance.currentUser = toFireUser(user)

  if (window.Intercom && user?.uid) {
    const localApi = $axios.create()
    const basePath = app.router?.options?.base || '/'
    localApi.setBaseURL(`${basePath}api`)
    const userHash = await createIntercomHash(localApi, $logger, user.uid)
    window.Intercom('update', {
      user_id: user.uid,
      user_hash: userHash?.hash || '',
    })

    const datadogRum = (window as any).DD_RUM
    datadogRum?.setUserProperty('id', user.uid)
  }

  if (user) {
    const idToken = await user.getIdToken()
    $cookies.set('authToken', idToken)
  }

  const createdAt = user?.metadata.creationTime

  store.dispatch('account/onAuthStateChanged', {
    authUser: user,
    isExistingUser,
    createdAt,
  })

  store.dispatch('account/setFirstAuthRan')
}

const firebasePlugin: Plugin = (context, inject) => {
  const { $logger, $apiUsers, i18n, $config } = context

  const config = $config.isProd
    ? firebaseProductionConfig
    : firebaseStagingConfig

  initializeApp(config)

  const auth = getAuth()
  setPersistence(auth, browserLocalPersistence)

  const fire: IFirebasePlugin = {
    async signOut() {
      await signOut(auth)
    },
    async signInWithEmailAndPassword(email: string, password: string) {
      await signInWithEmailAndPassword(auth, email, password)
    },
    async verifyPasswordResetCode(outOfBandCode: string) {
      return await verifyPasswordResetCode(auth, outOfBandCode)
    },
    async resetPassword(outOfBandCode: string, newPassword: string) {
      return await confirmPasswordReset(auth, outOfBandCode, newPassword)
    },
    async signInWithSocialProvider({
      provider,
      idToken,
      rawNonce,
      hashedNonce,
      firstName,
      lastName,
    }) {
      if (!isSocialLoginProvider(provider)) {
        throw new Error(`Unsupported social login provider: ${provider}`)
      }

      auth.useDeviceLanguage()

      const authCredential =
        provider === AUTH_PROVIDERS.GOOGLE
          ? GoogleAuthProvider.credential(idToken)
          : new OAuthProvider('apple.com').credential({
              idToken,
              rawNonce,
            })

      const socialLoginResult = await socialSignup({
        client: $apiUsers,
        logger: $logger,
        locale: i18n.locale,
        idToken,
        nonce: hashedNonce,
        firstName,
        lastName,
      })

      if (socialLoginResult.success) {
        try {
          const result = await signInWithCredential(auth, authCredential)

          onSuccessfulAuthStateChange({
            context,
            fireInstance: fire,
            user: result.user,
            isExistingUser: socialLoginResult.isExistingUser,
          })
        } catch (error) {
          $logger({
            level: ELogLevels.ERROR,
            msg: `Firebase signInWithCredential error: ${String(error)}`,
            logger: ELoggers.LOGIN,
            func: 'signInWithCredential',
          })
        }
      }

      return socialLoginResult
    },
    currentUser: toFireUser(auth.currentUser),
  }

  onAuthStateChanged(auth, async (user) => {
    await onSuccessfulAuthStateChange({
      context,
      fireInstance: fire,
      user,
    })
  })

  inject('fire', fire)
}

function toFireUser(user: User | null): FireUser {
  return {
    email: user?.email ?? '',
    emailVerified: Boolean(user?.emailVerified),
    uid: user?.uid ?? '',
    displayName: user?.displayName ?? '',
    phoneNumber: user?.phoneNumber ?? '',
    photoURL: user?.photoURL ?? '',
    getIdToken: async () => (await user?.getIdToken()) ?? '',
  }
}

export default firebasePlugin
