import { render, staticRenderFns } from "./default.vue?vue&type=template&id=675c39ac&scoped=true&"
import script from "./default.vue?vue&type=script&lang=ts&"
export * from "./default.vue?vue&type=script&lang=ts&"
import style0 from "./default.vue?vue&type=style&index=0&id=675c39ac&scoped=true&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "675c39ac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoutSuccesOverlay: require('/app/build/components/account/LogoutSuccesOverlay/LogoutSuccesOverlay.vue').default})
