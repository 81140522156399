export enum ESignupErrorCodes {
  EMAIL_EXISTS = '409',
}

export const ESMSVerificationErrors: Record<string, string> = {
  'flink.users.verify.send_otp_error': 'sms_verify_error_max_send_attempt',
  'flink.users.verify.check_otp_error':
    'sms_verify_error_unsuccessful_verification_check',
}

export enum EResetPasswordErrorCodes {
  INVALID_OOB_CODE = 'auth/invalid-action-code',
  EXPIRED_OOB_CODE = 'auth/expired-action-code',
}

export enum EReferralProgramErrorCodes {
  NOT_FOUND = 404,
  USER_NOT_ELIGIBLE = 406,
}

export const SOCIAL_LOGIN_PROVIDERS = {
  GOOGLE: 'google.com',
  APPLE: 'apple.com',
} as const

export const AUTH_PROVIDERS = {
  PASSWORD: 'password',
  ...SOCIAL_LOGIN_PROVIDERS,
} as const

export type AuthProvider = typeof AUTH_PROVIDERS[keyof typeof AUTH_PROVIDERS]
export type SocialLoginProvider =
  typeof SOCIAL_LOGIN_PROVIDERS[keyof typeof SOCIAL_LOGIN_PROVIDERS]

export interface IAuthDetails {
  provider: AuthProvider
  isExistingUser: boolean
}
