import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosResponse, AxiosError } from 'axios'
import { fixAPILocale } from '~/lib/helpers'
import {
  EHTTPMethods,
  ELogLevels,
  EStatus,
  LoggerFunction,
  ELoggers,
} from '~/types/app/Logger'

export default async function post<ResponseType = undefined>(
  client: NuxtAxiosInstance,
  logger: LoggerFunction,
  url: string,
  headers: Record<string, string | false | undefined> = {},
  body?: unknown
): Promise<AxiosResponse<ResponseType>> {
  const { Authorization, ...otherHeaders } = headers as Record<string, string>

  Object.keys(otherHeaders).forEach((header) =>
    client.setHeader(
      header,
      header === 'locale'
        ? fixAPILocale(otherHeaders[header])
        : otherHeaders[header]
    )
  )

  try {
    const response: AxiosResponse<ResponseType> = await client.post(
      url,
      body,
      Authorization ? { headers: { Authorization } } : undefined
    )

    logger({
      level: ELogLevels.INFO,
      msg: `POST Call successful: ${url}`,
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': EStatus.SUCCESSFUL,
      logger: ELoggers.API_CONTROLLERS,
      func: 'post',
      slug: 'api.post.success',
    })

    return response
  } catch (error: AxiosError | any) {
    const statusCode = (error as AxiosError).response?.status || EStatus.FAILED

    logger({
      level: statusCode === 404 ? ELogLevels.WARNING : ELogLevels.ERROR,
      msg: 'POST Call failed',
      'http.method': EHTTPMethods.POST,
      'http.url': url,
      'http.status_code': String(statusCode),
      error: error as string,
      logger: ELoggers.API_CONTROLLERS,
      func: 'post',
      slug: 'api.post.error',
    })

    throw error
  }
}
