import { Plugin, NuxtAppOptions } from '@nuxt/types'
import { AxiosRequestConfig } from 'axios'
import { getUserFlinkUID } from '~/lib/helpers'

export const axiosOnRequest = async (
  config: AxiosRequestConfig,
  app: NuxtAppOptions
): Promise<AxiosRequestConfig> => {
  // Add unique user-agent for API calls
  if (process.server) {
    config.headers.common['User-Agent'] = `FlinkWeb/Server (${
      process.env.RUNTIME_ENV || 'dev'
    })`
  }

  const flinkUid = getUserFlinkUID(app.$cookies)
  if (flinkUid) {
    config.headers.common['Optimizely-Id'] = flinkUid
  }

  const segmentAnonymousUserId =
    app.store?.state.account.segmentAnonymousUserId ||
    app.$cookies.get('ajs_anonymous_id')

  if (segmentAnonymousUserId) {
    config.headers.common['Anonymous-Id'] = segmentAnonymousUserId
  }

  config.headers.common['user-tracking-id'] =
    flinkUid || app.store?.state.account.defaultUserId

  const overrideToken = app.router?.currentRoute.query.token
  const token = await app.$fire?.currentUser?.getIdToken()
  const storeAccessToken = app.store?.state.account.accessToken

  if (storeAccessToken || token || overrideToken) {
    config.headers.common = {
      ...(config.headers.common || {}),
      Authorization: `Bearer ${storeAccessToken || token || overrideToken}`,
    }
  }

  return config
}

const axiosPlugin: Plugin = ({ $axios, app }) => {
  $axios.onRequest((config) => {
    return axiosOnRequest(config, app)
  })
}

export default axiosPlugin
