

























import { computed } from '@nuxtjs/composition-api';
import { EBannerMessageVariant } from '~/types/app/BannerMessage';
import { defineComponent } from '@nuxtjs/composition-api';

const __sfc_main = defineComponent({});

__sfc_main.props = {
  title: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  variant: {
    type: (String as () => EBannerMessageVariant),
    default: EBannerMessageVariant.INFO
  },
  closeable: {
    type: Boolean,
    default: false
  },
  clickable: {
    type: Boolean,
    default: false
  },
  showArrowIcon: {
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const messageMap = {
    [EBannerMessageVariant.INFO]: '--info',
    [EBannerMessageVariant.WARNING]: '--warning',
    [EBannerMessageVariant.SUCCESS]: '--success',
    [EBannerMessageVariant.CRITICAL]: '--critical',
    [EBannerMessageVariant.PROTECT]: '--protect',
    [EBannerMessageVariant.PRIME]: '--prime'
  };
  const variantClass = computed<string>(() => {
    return messageMap[props.variant];
  });
  const iconMap = {
    [EBannerMessageVariant.INFO]: 'info',
    [EBannerMessageVariant.WARNING]: 'caution',
    [EBannerMessageVariant.SUCCESS]: 'info',
    [EBannerMessageVariant.CRITICAL]: 'caution',
    [EBannerMessageVariant.PROTECT]: 'shield',
    [EBannerMessageVariant.PRIME]: 'logo-prime'
  };
  const leadingIcon = computed<string>(() => {
    if (props.icon) {
      return props.icon;
    }

    return iconMap[props.variant];
  });

  function handleClick() {
    if (props.clickable) {
      emit('click');
    }
  }

  return {
    emit,
    variantClass,
    leadingIcon,
    handleClick
  };
};

export default __sfc_main;
