/* eslint-disable camelcase */
import IPrice, { IPricePerUnit } from './Price'
import { IProductPromotions } from './Promotions'

const VARIANT_BOTTOM_SHEET = 'bottom_sheet'
export const VARIANT_LABEL_AND_BOTTOM_SHEET = 'label_and_bottom_sheet'

type OosExperimentVariant =
  | typeof VARIANT_BOTTOM_SHEET
  | typeof VARIANT_LABEL_AND_BOTTOM_SHEET

export interface IProductBaseUnit {
  unit: string
  amount: number
}

export interface IProductRaw {
  id: string
  sku: string
  name: string
  slug: string
  quantity: number
  thumbnail: string
  max_single_order_quantity: number
  tags?: string[]
  price?: IPrice
  pfand?: string
  deposit?: IPrice
  base_price?: IPrice
  base_unit?: IProductBaseUnit
  packaging_fee?: IPrice
  productContext?: string
  promotions?: IProductPromotions
  oos_experiment_variant?: OosExperimentVariant
  is_out_of_stock?: boolean
}

export default interface IProduct
  extends Omit<
    IProductRaw,
    | 'price'
    | 'pfand'
    | 'base_price'
    | 'base_unit'
    | 'packaging_fee'
    | 'max_single_order_quantity'
    | 'oos_experiment_variant'
    | 'is_out_of_stock'
  > {
  maxQuantity: number
  price: IPrice
  originalPrice?: IPrice
  packagingFee?: IPrice
  pricePerUnit?: IPricePerUnit
  discount?: number
  context?: string
  oosExperimentVariant?: OosExperimentVariant
  isOutOfStock?: boolean
}

export interface IOrderDetailsProduct {
  id: string
  product_name: string
  product_sku: string
  quantity: number
  thumbnail: string
  total_price: IPrice
  pricePerUnit?: IPricePerUnit
}

export interface IProductAmountBySKU {
  id: string
  product_sku: string
  quantity: number
}

export enum EProductTags {
  NLMedicine = 'nl-medicine',
}

export interface IProductPriceMetaData {
  discount_applied: boolean
  discount_percentage: number
  original_price?: IPrice
  price_per_unit?: IPricePerUnit
}

export interface IProductPriceRaw {
  sku: string
  price: IPrice
  metadata: IProductPriceMetaData
}

export interface IProductSubstitutionRaw {
  original_product: IProductRaw
  substitutes: IProductRaw[]
}

export interface IProductSubstitution {
  original_product: IProduct
  substitutes: IProduct[]
}

export enum ECardSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}
