export enum ELogLevels {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export enum EHTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum EStatus {
  FAILED = 'FAILED',
  SUCCESSFUL = 'SUCCESSFUL',
}

export enum ELoggers {
  API_CONTROLLERS = 'API Controllers',
  CART = 'Cart',
  PAYMENT = 'Payment',
  CHECKOUT = 'Checkout',
  PRODUCT = 'Product',
  CATEGORY = 'Category',
  ORDER = 'Order Status',
  EXPERIMENT = 'Experiment',
  SUBSCRIPTION = 'Subscription',
  DEALS = 'deals',
  PAYMENT_BFF = 'Payment BFF',
  LOGIN = 'Login',
}

export interface ILogOptions {
  level: ELogLevels
  msg: string
  'http.method'?: EHTTPMethods
  'http.status_code'?: string
  'http.url'?: string
  'http.useragent'?: string
  error?: string
  logger?: string | Record<string, string | undefined> | ELoggers
  func?: string
  warning?: string
  host?: string
  slug?: string
  'flink.user.country_code'?: string
  'flink.user.locale'?: string
  'flink.user.email_hash'?: string // sensitive user information shouldn't be logged
  'flink.user.id'?: string
  'flink.cart.id'?: string
  'flink.cart.shipping_method'?: string
  'flink.hub.id'?: string
  'flink.hub.slug'?: string
  'flink.product.id'?: string
  'flink.product.sku'?: string
  'flink.order.id'?: string
  'flink.payment.type'?: string | unknown
  'flink.payment.isExpressCheckout'?: boolean
  forceSubmit?: boolean
}

export type LoggerFunction = (options: ILogOptions) => void
