import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AddAddressBanner: () => import('../../components/AddAddressBanner/AddAddressBanner.vue' /* webpackChunkName: "components/add-address-banner" */).then(c => wrapFunctional(c.default || c)),
  BannerMessage: () => import('../../components/BannerMessage/BannerMessage.vue' /* webpackChunkName: "components/banner-message" */).then(c => wrapFunctional(c.default || c)),
  BottomNavigation: () => import('../../components/BottomNavigation/BottomNavigation.vue' /* webpackChunkName: "components/bottom-navigation" */).then(c => wrapFunctional(c.default || c)),
  Button: () => import('../../components/Button/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c)),
  ButtonSelection: () => import('../../components/ButtonSelection/ButtonSelection.vue' /* webpackChunkName: "components/button-selection" */).then(c => wrapFunctional(c.default || c)),
  CancelOrderCTA: () => import('../../components/CancelOrderCTA/CancelOrderCTA.vue' /* webpackChunkName: "components/cancel-order-c-t-a" */).then(c => wrapFunctional(c.default || c)),
  CancelOrderForm: () => import('../../components/CancelOrderForm/CancelOrderForm.vue' /* webpackChunkName: "components/cancel-order-form" */).then(c => wrapFunctional(c.default || c)),
  CancelResponseInfo: () => import('../../components/CancelResponseInfo/CancelResponseInfo.vue' /* webpackChunkName: "components/cancel-response-info" */).then(c => wrapFunctional(c.default || c)),
  CartClearedModal: () => import('../../components/CartClearedModal/CartClearedModal.vue' /* webpackChunkName: "components/cart-cleared-modal" */).then(c => wrapFunctional(c.default || c)),
  CartErrorModal: () => import('../../components/CartErrorModal/CartErrorModal.vue' /* webpackChunkName: "components/cart-error-modal" */).then(c => wrapFunctional(c.default || c)),
  CategoriesSwimlane: () => import('../../components/CategoriesSwimlane/CategoriesSwimlane.vue' /* webpackChunkName: "components/categories-swimlane" */).then(c => wrapFunctional(c.default || c)),
  CategoryCard: () => import('../../components/CategoryCard/CategoryCard.vue' /* webpackChunkName: "components/category-card" */).then(c => wrapFunctional(c.default || c)),
  CategoryHeading: () => import('../../components/CategoryHeading/CategoryHeading.vue' /* webpackChunkName: "components/category-heading" */).then(c => wrapFunctional(c.default || c)),
  ClickCollectCard: () => import('../../components/ClickCollectCard/ClickCollectCard.vue' /* webpackChunkName: "components/click-collect-card" */).then(c => wrapFunctional(c.default || c)),
  ClickCollectDistance: () => import('../../components/ClickCollectDistance/ClickCollectDistance.vue' /* webpackChunkName: "components/click-collect-distance" */).then(c => wrapFunctional(c.default || c)),
  ClickCollectInput: () => import('../../components/ClickCollectInput/ClickCollectInput.vue' /* webpackChunkName: "components/click-collect-input" */).then(c => wrapFunctional(c.default || c)),
  ClickCollectTimeHint: () => import('../../components/ClickCollectTimeHint/ClickCollectTimeHint.vue' /* webpackChunkName: "components/click-collect-time-hint" */).then(c => wrapFunctional(c.default || c)),
  CollectionCard: () => import('../../components/CollectionCard/CollectionCard.vue' /* webpackChunkName: "components/collection-card" */).then(c => wrapFunctional(c.default || c)),
  CollectionsSwimlane: () => import('../../components/CollectionsSwimlane/CollectionsSwimlane.vue' /* webpackChunkName: "components/collections-swimlane" */).then(c => wrapFunctional(c.default || c)),
  CollectionsSwimlaneV2: () => import('../../components/CollectionsSwimlane/CollectionsSwimlaneV2.vue' /* webpackChunkName: "components/collections-swimlane-v2" */).then(c => wrapFunctional(c.default || c)),
  ContentfulImage: () => import('../../components/ContentfulImage/ContentfulImage.vue' /* webpackChunkName: "components/contentful-image" */).then(c => wrapFunctional(c.default || c)),
  DefaultPaymentMethod: () => import('../../components/DefaultPaymentMethod/DefaultPaymentMethod.vue' /* webpackChunkName: "components/default-payment-method" */).then(c => wrapFunctional(c.default || c)),
  DeliveryAddressInput: () => import('../../components/DeliveryAddressInput/DeliveryAddressInput.vue' /* webpackChunkName: "components/delivery-address-input" */).then(c => wrapFunctional(c.default || c)),
  DeliveryBanner: () => import('../../components/DeliveryBanner/DeliveryBanner.vue' /* webpackChunkName: "components/delivery-banner" */).then(c => wrapFunctional(c.default || c)),
  DeliveryBannerGenericPopover: () => import('../../components/DeliveryBannerGenericPopover/DeliveryBannerGenericPopover.vue' /* webpackChunkName: "components/delivery-banner-generic-popover" */).then(c => wrapFunctional(c.default || c)),
  DeliveryTimeOptionOnCheckout: () => import('../../components/DeliveryTimeOptionOnCheckout/DeliveryTimeOptionOnCheckout.vue' /* webpackChunkName: "components/delivery-time-option-on-checkout" */).then(c => wrapFunctional(c.default || c)),
  DeliveryTimeOptionWrapper: () => import('../../components/DeliveryTimeOptionsWrapper/DeliveryTimeOptionWrapper.vue' /* webpackChunkName: "components/delivery-time-option-wrapper" */).then(c => wrapFunctional(c.default || c)),
  DeliveryTimeStaticMap: () => import('../../components/DeliveryTimeStaticMap/DeliveryTimeStaticMap.vue' /* webpackChunkName: "components/delivery-time-static-map" */).then(c => wrapFunctional(c.default || c)),
  DiscountLabel: () => import('../../components/DiscountLabel/DiscountLabel.vue' /* webpackChunkName: "components/discount-label" */).then(c => wrapFunctional(c.default || c)),
  Divider: () => import('../../components/Divider/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c)),
  Drawer: () => import('../../components/Drawer/Drawer.vue' /* webpackChunkName: "components/drawer" */).then(c => wrapFunctional(c.default || c)),
  DropArea: () => import('../../components/DropArea/DropArea.vue' /* webpackChunkName: "components/drop-area" */).then(c => wrapFunctional(c.default || c)),
  Experiment: () => import('../../components/Experiment/Experiment.vue' /* webpackChunkName: "components/experiment" */).then(c => wrapFunctional(c.default || c)),
  ExpressCheckoutButton: () => import('../../components/ExpressCheckoutButton/ExpressCheckoutButton.vue' /* webpackChunkName: "components/express-checkout-button" */).then(c => wrapFunctional(c.default || c)),
  FeatureFlag: () => import('../../components/FeatureFlag/FeatureFlag.vue' /* webpackChunkName: "components/feature-flag" */).then(c => wrapFunctional(c.default || c)),
  FeeInfoModal: () => import('../../components/FeeInfoModal/FeeInfoModal.vue' /* webpackChunkName: "components/fee-info-modal" */).then(c => wrapFunctional(c.default || c)),
  FormUserDetails: () => import('../../components/FormUserDetails/FormUserDetails.vue' /* webpackChunkName: "components/form-user-details" */).then(c => wrapFunctional(c.default || c)),
  GenericErrorModal: () => import('../../components/GenericErrorModal/GenericErrorModal.vue' /* webpackChunkName: "components/generic-error-modal" */).then(c => wrapFunctional(c.default || c)),
  GetDirections: () => import('../../components/GetDirections/GetDirections.vue' /* webpackChunkName: "components/get-directions" */).then(c => wrapFunctional(c.default || c)),
  CategoriesPopover: () => import('../../components/GlobalHeader/CategoriesPopover.vue' /* webpackChunkName: "components/categories-popover" */).then(c => wrapFunctional(c.default || c)),
  GlobalHeader: () => import('../../components/GlobalHeader/GlobalHeader.vue' /* webpackChunkName: "components/global-header" */).then(c => wrapFunctional(c.default || c)),
  NavbarSecondary: () => import('../../components/GlobalHeader/NavbarSecondary.vue' /* webpackChunkName: "components/navbar-secondary" */).then(c => wrapFunctional(c.default || c)),
  HelpButton: () => import('../../components/HelpButton/HelpButton.vue' /* webpackChunkName: "components/help-button" */).then(c => wrapFunctional(c.default || c)),
  HelpCenterProductItem: () => import('../../components/HelpCenterProductItem/HelpCenterProductItem.vue' /* webpackChunkName: "components/help-center-product-item" */).then(c => wrapFunctional(c.default || c)),
  HighDeliveryTimeTooltip: () => import('../../components/HighDeliveryTimeTooltip/HighDeliveryTimeTooltip.vue' /* webpackChunkName: "components/high-delivery-time-tooltip" */).then(c => wrapFunctional(c.default || c)),
  ImageSlider: () => import('../../components/ImageSlider/ImageSlider.vue' /* webpackChunkName: "components/image-slider" */).then(c => wrapFunctional(c.default || c)),
  InStorePaymentLoadingModal: () => import('../../components/InStorePaymentLoadingModal/InStorePaymentLoadingModal.vue' /* webpackChunkName: "components/in-store-payment-loading-modal" */).then(c => wrapFunctional(c.default || c)),
  InStorePaymentsCard: () => import('../../components/InStorePaymentsCard/InStorePaymentsCard.vue' /* webpackChunkName: "components/in-store-payments-card" */).then(c => wrapFunctional(c.default || c)),
  InstorePaymentsPayButton: () => import('../../components/InstorePaymentsPayButton/InstorePaymentsPayButton.vue' /* webpackChunkName: "components/instore-payments-pay-button" */).then(c => wrapFunctional(c.default || c)),
  ItemUnavailableModal: () => import('../../components/ItemUnavailableModal/ItemUnavailableModal.vue' /* webpackChunkName: "components/item-unavailable-modal" */).then(c => wrapFunctional(c.default || c)),
  LocalesSwitchModal: () => import('../../components/LocalesSwitchModal/LocalesSwitchModal.vue' /* webpackChunkName: "components/locales-switch-modal" */).then(c => wrapFunctional(c.default || c)),
  LocationChangeHintPopover: () => import('../../components/LocationChangeHintPopover/LocationChangeHintPopover.vue' /* webpackChunkName: "components/location-change-hint-popover" */).then(c => wrapFunctional(c.default || c)),
  Lottie: () => import('../../components/Lottie/LazyLottie.vue' /* webpackChunkName: "components/lazy-lottie" */).then(c => wrapFunctional(c.default || c)),
  MainFooter: () => import('../../components/MainFooter/MainFooter.vue' /* webpackChunkName: "components/main-footer" */).then(c => wrapFunctional(c.default || c)),
  BannerContent: () => import('../../components/MarketingBannerSlider/BannerContent.vue' /* webpackChunkName: "components/banner-content" */).then(c => wrapFunctional(c.default || c)),
  MarketingBannerSlider: () => import('../../components/MarketingBannerSlider/MarketingBannerSlider.vue' /* webpackChunkName: "components/marketing-banner-slider" */).then(c => wrapFunctional(c.default || c)),
  MarketingBannerSliderItem: () => import('../../components/MarketingBannerSlider/MarketingBannerSliderItem.vue' /* webpackChunkName: "components/marketing-banner-slider-item" */).then(c => wrapFunctional(c.default || c)),
  VideoPlayer: () => import('../../components/MarketingBannerSlider/VideoPlayer.vue' /* webpackChunkName: "components/video-player" */).then(c => wrapFunctional(c.default || c)),
  MasterCardPromotion: () => import('../../components/MasterCardPromotion/MasterCardPromotion.vue' /* webpackChunkName: "components/master-card-promotion" */).then(c => wrapFunctional(c.default || c)),
  MasterCardPromotionConditionsModal: () => import('../../components/MasterCardPromotionConditionsModal/MasterCardPromotionConditionsModal.vue' /* webpackChunkName: "components/master-card-promotion-conditions-modal" */).then(c => wrapFunctional(c.default || c)),
  Menu: () => import('../../components/Menu/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c)),
  MenuItem: () => import('../../components/Menu/MenuItem.vue' /* webpackChunkName: "components/menu-item" */).then(c => wrapFunctional(c.default || c)),
  ModalBackButton: () => import('../../components/ModalBackButton/ModalBackButton.vue' /* webpackChunkName: "components/modal-back-button" */).then(c => wrapFunctional(c.default || c)),
  NLMedicineLogo: () => import('../../components/NLMedicineLogo/NLMedicineLogo.vue' /* webpackChunkName: "components/n-l-medicine-logo" */).then(c => wrapFunctional(c.default || c)),
  NLMedicineModal: () => import('../../components/NLMedicineModal/NLMedicineModal.vue' /* webpackChunkName: "components/n-l-medicine-modal" */).then(c => wrapFunctional(c.default || c)),
  NavBar: () => import('../../components/NavBar/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c)),
  OrderFeedbackModal: () => import('../../components/OrderFeedbackModal/OrderFeedbackModal.vue' /* webpackChunkName: "components/order-feedback-modal" */).then(c => wrapFunctional(c.default || c)),
  OrderHistoryItem: () => import('../../components/OrderHistoryItem/OrderHistoryItem.vue' /* webpackChunkName: "components/order-history-item" */).then(c => wrapFunctional(c.default || c)),
  OrderItemsThumbnails: () => import('../../components/OrderItemsThumbnails/OrderItemsThumbnails.vue' /* webpackChunkName: "components/order-items-thumbnails" */).then(c => wrapFunctional(c.default || c)),
  OrderProgress: () => import('../../components/OrderProgress/OrderProgress.vue' /* webpackChunkName: "components/order-progress" */).then(c => wrapFunctional(c.default || c)),
  OrderStatusRow: () => import('../../components/OrderStatusRow/OrderStatusRow.vue' /* webpackChunkName: "components/order-status-row" */).then(c => wrapFunctional(c.default || c)),
  OrderStatusStep: () => import('../../components/OrderStatusStep/OrderStatusStep.vue' /* webpackChunkName: "components/order-status-step" */).then(c => wrapFunctional(c.default || c)),
  OrderSummary: () => import('../../components/OrderSummary/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c)),
  OutOfStockModal: () => import('../../components/OutOfStockModal/OutOfStockModal.vue' /* webpackChunkName: "components/out-of-stock-modal" */).then(c => wrapFunctional(c.default || c)),
  PDPTable: () => import('../../components/PDP/PDPTable.vue' /* webpackChunkName: "components/p-d-p-table" */).then(c => wrapFunctional(c.default || c)),
  PageContentArea: () => import('../../components/PageContentArea/PageContentArea.vue' /* webpackChunkName: "components/page-content-area" */).then(c => wrapFunctional(c.default || c)),
  PayCollectModal: () => import('../../components/PayCollectModal/PayCollectModal.vue' /* webpackChunkName: "components/pay-collect-modal" */).then(c => wrapFunctional(c.default || c)),
  PaymentErrorModal: () => import('../../components/PaymentErrorModal/PaymentErrorModal.vue' /* webpackChunkName: "components/payment-error-modal" */).then(c => wrapFunctional(c.default || c)),
  PaymentForm: () => import('../../components/PaymentForm/PaymentForm.vue' /* webpackChunkName: "components/payment-form" */).then(c => wrapFunctional(c.default || c)),
  PaymentLoadingModal: () => import('../../components/PaymentLoadingModal/PaymentLoadingModal.vue' /* webpackChunkName: "components/payment-loading-modal" */).then(c => wrapFunctional(c.default || c)),
  PaymentModal: () => import('../../components/PaymentModal/PaymentModal.vue' /* webpackChunkName: "components/payment-modal" */).then(c => wrapFunctional(c.default || c)),
  PlusHomeTeaser: () => import('../../components/PlusHomeTeaser/PlusHomeTeaser.vue' /* webpackChunkName: "components/plus-home-teaser" */).then(c => wrapFunctional(c.default || c)),
  PlusPaymentForm: () => import('../../components/PlusPaymentForm/PlusPaymentForm.vue' /* webpackChunkName: "components/plus-payment-form" */).then(c => wrapFunctional(c.default || c)),
  PlusSignupModal: () => import('../../components/PlusSIgnupModal/PlusSignupModal.vue' /* webpackChunkName: "components/plus-signup-modal" */).then(c => wrapFunctional(c.default || c)),
  PlusSignupModalPay: () => import('../../components/PlusSIgnupModal/PlusSignupModalPay.vue' /* webpackChunkName: "components/plus-signup-modal-pay" */).then(c => wrapFunctional(c.default || c)),
  PlusSignupModalSelect: () => import('../../components/PlusSIgnupModal/PlusSignupModalSelect.vue' /* webpackChunkName: "components/plus-signup-modal-select" */).then(c => wrapFunctional(c.default || c)),
  PlusSignupModalSuccess: () => import('../../components/PlusSIgnupModal/PlusSignupModalSuccess.vue' /* webpackChunkName: "components/plus-signup-modal-success" */).then(c => wrapFunctional(c.default || c)),
  PrepTimeHint: () => import('../../components/PrepTimeHint/PrepTimeHint.vue' /* webpackChunkName: "components/prep-time-hint" */).then(c => wrapFunctional(c.default || c)),
  PriceTag: () => import('../../components/PriceTag/PriceTag.vue' /* webpackChunkName: "components/price-tag" */).then(c => wrapFunctional(c.default || c)),
  PrimeBenefitsSection: () => import('../../components/PrimeBenefitsSection/PrimeBenefitsSection.vue' /* webpackChunkName: "components/prime-benefits-section" */).then(c => wrapFunctional(c.default || c)),
  PrimeCancelConfirmModal: () => import('../../components/PrimeCancelConfirmModal/PrimeCancelConfirmModal.vue' /* webpackChunkName: "components/prime-cancel-confirm-modal" */).then(c => wrapFunctional(c.default || c)),
  PrimeCancelFormModal: () => import('../../components/PrimeCancelFormModal/PrimeCancelFormModal.vue' /* webpackChunkName: "components/prime-cancel-form-modal" */).then(c => wrapFunctional(c.default || c)),
  PrimeCard: () => import('../../components/PrimeCard/PrimeCard.vue' /* webpackChunkName: "components/prime-card" */).then(c => wrapFunctional(c.default || c)),
  PrimeCartBanner: () => import('../../components/PrimeCartBanner/PrimeCartBanner.vue' /* webpackChunkName: "components/prime-cart-banner" */).then(c => wrapFunctional(c.default || c)),
  PrimeHomeBanner: () => import('../../components/PrimeHomeBanner/PrimeHomeBanner.vue' /* webpackChunkName: "components/prime-home-banner" */).then(c => wrapFunctional(c.default || c)),
  PrimeOrderSummaryBanner: () => import('../../components/PrimeOrderSummaryBanner/PrimeOrderSummaryBanner.vue' /* webpackChunkName: "components/prime-order-summary-banner" */).then(c => wrapFunctional(c.default || c)),
  PrimeOrderSummaryMovBanner: () => import('../../components/PrimeOrderSummaryMovBanner/PrimeOrderSummaryMovBanner.vue' /* webpackChunkName: "components/prime-order-summary-mov-banner" */).then(c => wrapFunctional(c.default || c)),
  PrimeOrderSummaryPriceTag: () => import('../../components/PrimeOrderSummaryPriceTag/PrimeOrderSummaryPriceTag.vue' /* webpackChunkName: "components/prime-order-summary-price-tag" */).then(c => wrapFunctional(c.default || c)),
  PrimeProfileBanner: () => import('../../components/PrimeProfileBanner/PrimeProfileBanner.vue' /* webpackChunkName: "components/prime-profile-banner" */).then(c => wrapFunctional(c.default || c)),
  ProductCard: () => import('../../components/ProductCard/ProductCard.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c)),
  ProductCardGrid: () => import('../../components/ProductCardGrid/ProductCardGrid.vue' /* webpackChunkName: "components/product-card-grid" */).then(c => wrapFunctional(c.default || c)),
  ProductList: () => import('../../components/ProductList/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c)),
  ProductListDetails: () => import('../../components/ProductList/ProductListDetails.vue' /* webpackChunkName: "components/product-list-details" */).then(c => wrapFunctional(c.default || c)),
  ProductListItem: () => import('../../components/ProductList/ProductListItem.vue' /* webpackChunkName: "components/product-list-item" */).then(c => wrapFunctional(c.default || c)),
  ProductListTable: () => import('../../components/ProductList/ProductListTable.vue' /* webpackChunkName: "components/product-list-table" */).then(c => wrapFunctional(c.default || c)),
  ProductListWithPromotions: () => import('../../components/ProductList/ProductListWithPromotions.vue' /* webpackChunkName: "components/product-list-with-promotions" */).then(c => wrapFunctional(c.default || c)),
  PromotionLabel: () => import('../../components/ProductList/PromotionLabel.vue' /* webpackChunkName: "components/promotion-label" */).then(c => wrapFunctional(c.default || c)),
  PromotionProductList: () => import('../../components/ProductList/PromotionProductList.vue' /* webpackChunkName: "components/promotion-product-list" */).then(c => wrapFunctional(c.default || c)),
  ProductListOverlay: () => import('../../components/ProductListOverlay/ProductListOverlay.vue' /* webpackChunkName: "components/product-list-overlay" */).then(c => wrapFunctional(c.default || c)),
  ProductSubstitutionCard: () => import('../../components/ProductSubstitutionCard/ProductSubstitutionCard.vue' /* webpackChunkName: "components/product-substitution-card" */).then(c => wrapFunctional(c.default || c)),
  ProductSubstitutionModal: () => import('../../components/ProductSubstitutionModal/ProductSubstitutionModal.vue' /* webpackChunkName: "components/product-substitution-modal" */).then(c => wrapFunctional(c.default || c)),
  ProductsSwimlane: () => import('../../components/ProductsSwimlane/ProductsSwimlane.vue' /* webpackChunkName: "components/products-swimlane" */).then(c => wrapFunctional(c.default || c)),
  ProgressBar: () => import('../../components/ProgressBar/ProgressBar.vue' /* webpackChunkName: "components/progress-bar" */).then(c => wrapFunctional(c.default || c)),
  QuantityControl: () => import('../../components/QuantityControl/QuantityControl.vue' /* webpackChunkName: "components/quantity-control" */).then(c => wrapFunctional(c.default || c)),
  Receipt: () => import('../../components/Receipt/Receipt.vue' /* webpackChunkName: "components/receipt" */).then(c => wrapFunctional(c.default || c)),
  RequestInvoiceForm: () => import('../../components/RequestInvoiceForm/RequestInvoiceForm.vue' /* webpackChunkName: "components/request-invoice-form" */).then(c => wrapFunctional(c.default || c)),
  RiderTip: () => import('../../components/RiderTip/RiderTip.vue' /* webpackChunkName: "components/rider-tip" */).then(c => wrapFunctional(c.default || c)),
  GlobalSearch: () => import('../../components/Search/GlobalSearch.vue' /* webpackChunkName: "components/global-search" */).then(c => wrapFunctional(c.default || c)),
  SearchControl: () => import('../../components/Search/SearchControl.vue' /* webpackChunkName: "components/search-control" */).then(c => wrapFunctional(c.default || c)),
  SearchDropdown: () => import('../../components/Search/SearchDropdown.vue' /* webpackChunkName: "components/search-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchLabels: () => import('../../components/Search/SearchLabels.vue' /* webpackChunkName: "components/search-labels" */).then(c => wrapFunctional(c.default || c)),
  SearchOverlay: () => import('../../components/Search/SearchOverlay.vue' /* webpackChunkName: "components/search-overlay" */).then(c => wrapFunctional(c.default || c)),
  SearchResults: () => import('../../components/Search/SearchResults.vue' /* webpackChunkName: "components/search-results" */).then(c => wrapFunctional(c.default || c)),
  SearchSuggestions: () => import('../../components/Search/SearchSuggestions.vue' /* webpackChunkName: "components/search-suggestions" */).then(c => wrapFunctional(c.default || c)),
  ServiceInfo: () => import('../../components/ServiceInfo/ServiceInfo.vue' /* webpackChunkName: "components/service-info" */).then(c => wrapFunctional(c.default || c)),
  ShopHomepageSections: () => import('../../components/ShopHomepageSections/ShopHomepageSections.vue' /* webpackChunkName: "components/shop-homepage-sections" */).then(c => wrapFunctional(c.default || c)),
  SimpleCartButton: () => import('../../components/SimpleCartButton/SimpleCartButton.vue' /* webpackChunkName: "components/simple-cart-button" */).then(c => wrapFunctional(c.default || c)),
  Snackbar: () => import('../../components/Snackbar/Snackbar.vue' /* webpackChunkName: "components/snackbar" */).then(c => wrapFunctional(c.default || c)),
  AppleSocialLoginButton: () => import('../../components/SocialLoginButtons/AppleSocialLoginButton.vue' /* webpackChunkName: "components/apple-social-login-button" */).then(c => wrapFunctional(c.default || c)),
  GoogleSocialLoginButton: () => import('../../components/SocialLoginButtons/GoogleSocialLoginButton.vue' /* webpackChunkName: "components/google-social-login-button" */).then(c => wrapFunctional(c.default || c)),
  Stepper: () => import('../../components/Stepper/Stepper.vue' /* webpackChunkName: "components/stepper" */).then(c => wrapFunctional(c.default || c)),
  StorageFee: () => import('../../components/StorageFee/StorageFee.vue' /* webpackChunkName: "components/storage-fee" */).then(c => wrapFunctional(c.default || c)),
  StoreBadge: () => import('../../components/StoreBadge/StoreBadge.vue' /* webpackChunkName: "components/store-badge" */).then(c => wrapFunctional(c.default || c)),
  StoreClosing: () => import('../../components/StoreClosing/StoreClosing.vue' /* webpackChunkName: "components/store-closing" */).then(c => wrapFunctional(c.default || c)),
  SuccessCard: () => import('../../components/SuccessCard/SuccessCard.vue' /* webpackChunkName: "components/success-card" */).then(c => wrapFunctional(c.default || c)),
  TimeSlotsModal: () => import('../../components/TimeSlotsModal/TimeSlotsModal.vue' /* webpackChunkName: "components/time-slots-modal" */).then(c => wrapFunctional(c.default || c)),
  TrackingDeliveryAddress: () => import('../../components/TrackingDeliveryAddress/TrackingDeliveryAddress.vue' /* webpackChunkName: "components/tracking-delivery-address" */).then(c => wrapFunctional(c.default || c)),
  TrackingOrderSummary: () => import('../../components/TrackingOrderSummary/TrackingOrderSummary.vue' /* webpackChunkName: "components/tracking-order-summary" */).then(c => wrapFunctional(c.default || c)),
  TryAgain: () => import('../../components/TryAgain/TryAgain.vue' /* webpackChunkName: "components/try-again" */).then(c => wrapFunctional(c.default || c)),
  VAccordion: () => import('../../components/VAccordion/VAccordion.vue' /* webpackChunkName: "components/v-accordion" */).then(c => wrapFunctional(c.default || c)),
  VAccordionPanel: () => import('../../components/VAccordion/VAccordionPanel.vue' /* webpackChunkName: "components/v-accordion-panel" */).then(c => wrapFunctional(c.default || c)),
  VAccordionTab: () => import('../../components/VAccordionTabs/VAccordionTab.vue' /* webpackChunkName: "components/v-accordion-tab" */).then(c => wrapFunctional(c.default || c)),
  VAccordionTabs: () => import('../../components/VAccordionTabs/VAccordionTabs.vue' /* webpackChunkName: "components/v-accordion-tabs" */).then(c => wrapFunctional(c.default || c)),
  VBackButton: () => import('../../components/VBackButton/VBackButton.vue' /* webpackChunkName: "components/v-back-button" */).then(c => wrapFunctional(c.default || c)),
  VBreadcrumb: () => import('../../components/VBreadcrumb/VBreadcrumb.vue' /* webpackChunkName: "components/v-breadcrumb" */).then(c => wrapFunctional(c.default || c)),
  VCategoryIcon: () => import('../../components/VCategoryIcon/VCategoryIcon.vue' /* webpackChunkName: "components/v-category-icon" */).then(c => wrapFunctional(c.default || c)),
  VCheckbox: () => import('../../components/VCheckbox/VCheckbox.vue' /* webpackChunkName: "components/v-checkbox" */).then(c => wrapFunctional(c.default || c)),
  VChip: () => import('../../components/VChip/VChip.vue' /* webpackChunkName: "components/v-chip" */).then(c => wrapFunctional(c.default || c)),
  VCloudinaryImage: () => import('../../components/VCloudinaryImage/VCloudinaryImage.vue' /* webpackChunkName: "components/v-cloudinary-image" */).then(c => wrapFunctional(c.default || c)),
  VIcon: () => import('../../components/VIcon/VIcon.vue' /* webpackChunkName: "components/v-icon" */).then(c => wrapFunctional(c.default || c)),
  VModal: () => import('../../components/VModal/VModal.vue' /* webpackChunkName: "components/v-modal" */).then(c => wrapFunctional(c.default || c)),
  VOverlay: () => import('../../components/VOverlay/VOverlay.vue' /* webpackChunkName: "components/v-overlay" */).then(c => wrapFunctional(c.default || c)),
  VPopover: () => import('../../components/VPopover/VPopover.vue' /* webpackChunkName: "components/v-popover" */).then(c => wrapFunctional(c.default || c)),
  VRadio: () => import('../../components/VRadio/VRadio.vue' /* webpackChunkName: "components/v-radio" */).then(c => wrapFunctional(c.default || c)),
  VRadioPlans: () => import('../../components/VRadioPlans/VRadioPlans.vue' /* webpackChunkName: "components/v-radio-plans" */).then(c => wrapFunctional(c.default || c)),
  VSelect: () => import('../../components/VSelect/VSelect.vue' /* webpackChunkName: "components/v-select" */).then(c => wrapFunctional(c.default || c)),
  VSlider: () => import('../../components/VSlider/VSlider.vue' /* webpackChunkName: "components/v-slider" */).then(c => wrapFunctional(c.default || c)),
  VSliderItem: () => import('../../components/VSlider/VSliderItem.vue' /* webpackChunkName: "components/v-slider-item" */).then(c => wrapFunctional(c.default || c)),
  VSwimlane: () => import('../../components/VSwimlane/VSwimlane.vue' /* webpackChunkName: "components/v-swimlane" */).then(c => wrapFunctional(c.default || c)),
  VTextField: () => import('../../components/VTextField/VTextField.vue' /* webpackChunkName: "components/v-text-field" */).then(c => wrapFunctional(c.default || c)),
  VToggleSwitch: () => import('../../components/VToggleSwitch/VToggleSwitch.vue' /* webpackChunkName: "components/v-toggle-switch" */).then(c => wrapFunctional(c.default || c)),
  VUnderlay: () => import('../../components/VUnderlay/VUnderlay.vue' /* webpackChunkName: "components/v-underlay" */).then(c => wrapFunctional(c.default || c)),
  VoucherCard: () => import('../../components/VoucherCard/VoucherCard.vue' /* webpackChunkName: "components/voucher-card" */).then(c => wrapFunctional(c.default || c)),
  VoucherCardInfo: () => import('../../components/VoucherCardInfo/VoucherCardInfo.vue' /* webpackChunkName: "components/voucher-card-info" */).then(c => wrapFunctional(c.default || c)),
  VoucherCardWProductImage: () => import('../../components/VoucherCardWProductImage/VoucherCardWProductImage.vue' /* webpackChunkName: "components/voucher-card-w-product-image" */).then(c => wrapFunctional(c.default || c)),
  VoucherInfoModal: () => import('../../components/VoucherInfoModal/VoucherInfoModal.vue' /* webpackChunkName: "components/voucher-info-modal" */).then(c => wrapFunctional(c.default || c)),
  VoucherInput: () => import('../../components/VoucherInput/VoucherInput.vue' /* webpackChunkName: "components/voucher-input" */).then(c => wrapFunctional(c.default || c)),
  VoucherWallet: () => import('../../components/VoucherWallet/VoucherWallet.vue' /* webpackChunkName: "components/voucher-wallet" */).then(c => wrapFunctional(c.default || c)),
  VoucherWalletEntry: () => import('../../components/VoucherWalletEntry/VoucherWalletEntry.vue' /* webpackChunkName: "components/voucher-wallet-entry" */).then(c => wrapFunctional(c.default || c)),
  VouchersSwimlane: () => import('../../components/VouchersSwimlane/VouchersSwimlane.vue' /* webpackChunkName: "components/vouchers-swimlane" */).then(c => wrapFunctional(c.default || c)),
  AgeVerification: () => import('../../components/age-verification/AgeVerification.vue' /* webpackChunkName: "components/age-verification" */).then(c => wrapFunctional(c.default || c)),
  AgeVerificationAlcohol: () => import('../../components/age-verification/AgeVerificationAlcohol.vue' /* webpackChunkName: "components/age-verification-alcohol" */).then(c => wrapFunctional(c.default || c)),
  AgeVerificationTobacco: () => import('../../components/age-verification/AgeVerificationTobacco.vue' /* webpackChunkName: "components/age-verification-tobacco" */).then(c => wrapFunctional(c.default || c)),
  CartButton: () => import('../../components/cart/CartButton.vue' /* webpackChunkName: "components/cart-button" */).then(c => wrapFunctional(c.default || c)),
  CartEmpty: () => import('../../components/cart/CartEmpty.vue' /* webpackChunkName: "components/cart-empty" */).then(c => wrapFunctional(c.default || c)),
  CartPopover: () => import('../../components/cart/CartPopover.vue' /* webpackChunkName: "components/cart-popover" */).then(c => wrapFunctional(c.default || c)),
  CityAreas: () => import('../../components/city/CityAreas.vue' /* webpackChunkName: "components/city-areas" */).then(c => wrapFunctional(c.default || c)),
  CityDownload: () => import('../../components/city/CityDownload.vue' /* webpackChunkName: "components/city-download" */).then(c => wrapFunctional(c.default || c)),
  CityFAQ: () => import('../../components/city/CityFAQ.vue' /* webpackChunkName: "components/city-f-a-q" */).then(c => wrapFunctional(c.default || c)),
  CityFavouriteItems: () => import('../../components/city/CityFavouriteItems.vue' /* webpackChunkName: "components/city-favourite-items" */).then(c => wrapFunctional(c.default || c)),
  CityGroceries: () => import('../../components/city/CityGroceries.vue' /* webpackChunkName: "components/city-groceries" */).then(c => wrapFunctional(c.default || c)),
  CitySeo: () => import('../../components/city/CitySeo.vue' /* webpackChunkName: "components/city-seo" */).then(c => wrapFunctional(c.default || c)),
  HomepageBanner: () => import('../../components/homepage/HomepageBanner.vue' /* webpackChunkName: "components/homepage-banner" */).then(c => wrapFunctional(c.default || c)),
  HomepageCities: () => import('../../components/homepage/HomepageCities.vue' /* webpackChunkName: "components/homepage-cities" */).then(c => wrapFunctional(c.default || c)),
  MainLayout: () => import('../../components/main/MainLayout.vue' /* webpackChunkName: "components/main-layout" */).then(c => wrapFunctional(c.default || c)),
  PendingRequestLayout: () => import('../../components/main/PendingRequestLayout.vue' /* webpackChunkName: "components/pending-request-layout" */).then(c => wrapFunctional(c.default || c)),
  RichTextRendererWithAnchors: () => import('../../components/main/RichTextRendererWithAnchors.vue' /* webpackChunkName: "components/rich-text-renderer-with-anchors" */).then(c => wrapFunctional(c.default || c)),
  VTab: () => import('../../components/tabs/VTab.vue' /* webpackChunkName: "components/v-tab" */).then(c => wrapFunctional(c.default || c)),
  VTabs: () => import('../../components/tabs/VTabs.vue' /* webpackChunkName: "components/v-tabs" */).then(c => wrapFunctional(c.default || c)),
  DeliveryBannerAddress: () => import('../../components/DeliveryBanner/DeliveryBannerAddress/DeliveryBannerAddress.vue' /* webpackChunkName: "components/delivery-banner-address" */).then(c => wrapFunctional(c.default || c)),
  DeliveryBannerETA: () => import('../../components/DeliveryBanner/DeliveryBannerETA/DeliveryBannerETA.vue' /* webpackChunkName: "components/delivery-banner-e-t-a" */).then(c => wrapFunctional(c.default || c)),
  ClosingSoonModal: () => import('../../components/StoreClosing/ClosingSoonModal/ClosingSoonModal.vue' /* webpackChunkName: "components/closing-soon-modal" */).then(c => wrapFunctional(c.default || c)),
  StoreClosedModal: () => import('../../components/StoreClosing/StoreClosedModal/StoreClosedModal.vue' /* webpackChunkName: "components/store-closed-modal" */).then(c => wrapFunctional(c.default || c)),
  ForgotPasswordForm: () => import('../../components/account/ForgotPasswordForm/ForgotPasswordForm.vue' /* webpackChunkName: "components/forgot-password-form" */).then(c => wrapFunctional(c.default || c)),
  LoginForm: () => import('../../components/account/LoginForm/LoginForm.vue' /* webpackChunkName: "components/login-form" */).then(c => wrapFunctional(c.default || c)),
  LogoutSuccesOverlay: () => import('../../components/account/LogoutSuccesOverlay/LogoutSuccesOverlay.vue' /* webpackChunkName: "components/logout-succes-overlay" */).then(c => wrapFunctional(c.default || c)),
  OtpVerificationForm: () => import('../../components/account/OtpVerificationForm/OtpVerificationForm.vue' /* webpackChunkName: "components/otp-verification-form" */).then(c => wrapFunctional(c.default || c)),
  PhoneNumberForm: () => import('../../components/account/PhoneNumberForm/PhoneNumberForm.vue' /* webpackChunkName: "components/phone-number-form" */).then(c => wrapFunctional(c.default || c)),
  RegisterForm: () => import('../../components/account/RegisterForm/RegisterForm.vue' /* webpackChunkName: "components/register-form" */).then(c => wrapFunctional(c.default || c)),
  ResetPasswordForm: () => import('../../components/account/ResetPasswordForm/ResetPasswordForm.vue' /* webpackChunkName: "components/reset-password-form" */).then(c => wrapFunctional(c.default || c)),
  ResetPasswordSuccess: () => import('../../components/account/ResetPasswordSuccess/ResetPasswordSuccess.vue' /* webpackChunkName: "components/reset-password-success" */).then(c => wrapFunctional(c.default || c)),
  AppDownloadSection: () => import('../../components/landing/AppDownloadSection/AppDownloadSection.vue' /* webpackChunkName: "components/app-download-section" */).then(c => wrapFunctional(c.default || c)),
  FAQSection: () => import('../../components/landing/FAQSection/FAQSection.vue' /* webpackChunkName: "components/f-a-q-section" */).then(c => wrapFunctional(c.default || c)),
  Hero: () => import('../../components/landing/Hero/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c)),
  LandingCategories: () => import('../../components/landing/LandingCategories/LandingCategories.vue' /* webpackChunkName: "components/landing-categories" */).then(c => wrapFunctional(c.default || c)),
  LandingCategoryCard: () => import('../../components/landing/LandingCategoryCard/LandingCategoryCard.vue' /* webpackChunkName: "components/landing-category-card" */).then(c => wrapFunctional(c.default || c)),
  LandingContentArea: () => import('../../components/landing/LandingContentArea/LandingContentArea.vue' /* webpackChunkName: "components/landing-content-area" */).then(c => wrapFunctional(c.default || c)),
  LandingImage: () => import('../../components/landing/LandingImage/LandingImage.vue' /* webpackChunkName: "components/landing-image" */).then(c => wrapFunctional(c.default || c)),
  LandingUsp: () => import('../../components/landing/LandingUSP/LandingUsp.vue' /* webpackChunkName: "components/landing-usp" */).then(c => wrapFunctional(c.default || c)),
  LandingUspContainer: () => import('../../components/landing/LandingUSPContainer/LandingUspContainer.vue' /* webpackChunkName: "components/landing-usp-container" */).then(c => wrapFunctional(c.default || c)),
  MapSection: () => import('../../components/landing/MapSection/MapSection.vue' /* webpackChunkName: "components/map-section" */).then(c => wrapFunctional(c.default || c)),
  RecipeCartModal: () => import('../../components/landing/RecipeCartModal/RecipeCartModal.vue' /* webpackChunkName: "components/recipe-cart-modal" */).then(c => wrapFunctional(c.default || c)),
  RecipeCreatorArea: () => import('../../components/landing/RecipeCreatorArea/RecipeCreatorArea.vue' /* webpackChunkName: "components/recipe-creator-area" */).then(c => wrapFunctional(c.default || c)),
  RecipeDirections: () => import('../../components/landing/RecipeDirections/RecipeDirections.vue' /* webpackChunkName: "components/recipe-directions" */).then(c => wrapFunctional(c.default || c)),
  RecipeHero: () => import('../../components/landing/RecipeHero/RecipeHero.vue' /* webpackChunkName: "components/recipe-hero" */).then(c => wrapFunctional(c.default || c)),
  RecipeIntro: () => import('../../components/landing/RecipeIntro/RecipeIntro.vue' /* webpackChunkName: "components/recipe-intro" */).then(c => wrapFunctional(c.default || c)),
  RecipeLabels: () => import('../../components/landing/RecipeLabels/RecipeLabels.vue' /* webpackChunkName: "components/recipe-labels" */).then(c => wrapFunctional(c.default || c)),
  RecipeRecommendations: () => import('../../components/landing/RecipeRecommendations/RecipeRecommendations.vue' /* webpackChunkName: "components/recipe-recommendations" */).then(c => wrapFunctional(c.default || c)),
  RecipesIngredientRow: () => import('../../components/landing/RecipesIngredients/RecipesIngredientRow.vue' /* webpackChunkName: "components/recipes-ingredient-row" */).then(c => wrapFunctional(c.default || c)),
  RecipesIngredients: () => import('../../components/landing/RecipesIngredients/RecipesIngredients.vue' /* webpackChunkName: "components/recipes-ingredients" */).then(c => wrapFunctional(c.default || c)),
  RecipesSidebar: () => import('../../components/landing/RecipesSidebar/RecipesSidebar.vue' /* webpackChunkName: "components/recipes-sidebar" */).then(c => wrapFunctional(c.default || c)),
  ReviewCard: () => import('../../components/landing/ReviewCard/ReviewCard.vue' /* webpackChunkName: "components/review-card" */).then(c => wrapFunctional(c.default || c)),
  Reviews: () => import('../../components/landing/Reviews/Reviews.vue' /* webpackChunkName: "components/reviews" */).then(c => wrapFunctional(c.default || c)),
  RiderAcquisition: () => import('../../components/landing/RiderAcquisition/RiderAcquisition.vue' /* webpackChunkName: "components/rider-acquisition" */).then(c => wrapFunctional(c.default || c)),
  SEOLinks: () => import('../../components/landing/SEOLinks/SEOLinks.vue' /* webpackChunkName: "components/s-e-o-links" */).then(c => wrapFunctional(c.default || c)),
  ServingsControl: () => import('../../components/landing/ServingsControl/ServingsControl.vue' /* webpackChunkName: "components/servings-control" */).then(c => wrapFunctional(c.default || c)),
  AccountFlowLayout: () => import('../../components/layout/AccountFlowLayout/AccountFlowLayout.vue' /* webpackChunkName: "components/account-flow-layout" */).then(c => wrapFunctional(c.default || c)),
  DefaultLayout: () => import('../../components/layout/DefaultLayout/DefaultLayout.vue' /* webpackChunkName: "components/default-layout" */).then(c => wrapFunctional(c.default || c)),
  ErrorLayout: () => import('../../components/layout/ErrorLayout/ErrorLayout.vue' /* webpackChunkName: "components/error-layout" */).then(c => wrapFunctional(c.default || c)),
  FunnelLayout: () => import('../../components/layout/FunnelLayout/FunnelLayout.vue' /* webpackChunkName: "components/funnel-layout" */).then(c => wrapFunctional(c.default || c)),
  HelpCenterLayout: () => import('../../components/layout/HelpCenterLayout/HelpCenterLayout.vue' /* webpackChunkName: "components/help-center-layout" */).then(c => wrapFunctional(c.default || c)),
  RecipeLayout: () => import('../../components/layout/RecipeLayout/RecipeLayout.vue' /* webpackChunkName: "components/recipe-layout" */).then(c => wrapFunctional(c.default || c)),
  AddressBook: () => import('../../components/onboarding/AddressBook/AddressBook.vue' /* webpackChunkName: "components/address-book" */).then(c => wrapFunctional(c.default || c)),
  AddressDeleteModal: () => import('../../components/onboarding/AddressBook/AddressDeleteModal.vue' /* webpackChunkName: "components/address-delete-modal" */).then(c => wrapFunctional(c.default || c)),
  AddressEditModal: () => import('../../components/onboarding/AddressBook/AddressEditModal.vue' /* webpackChunkName: "components/address-edit-modal" */).then(c => wrapFunctional(c.default || c)),
  AddressEntranceEditMap: () => import('../../components/onboarding/AddressBook/AddressEntranceEditMap.vue' /* webpackChunkName: "components/address-entrance-edit-map" */).then(c => wrapFunctional(c.default || c)),
  AddressList: () => import('../../components/onboarding/AddressBook/AddressList.vue' /* webpackChunkName: "components/address-list" */).then(c => wrapFunctional(c.default || c)),
  LocationAdditionalDataInput: () => import('../../components/onboarding/LocationAdditionalDataInput/LocationAdditionalDataInput.vue' /* webpackChunkName: "components/location-additional-data-input" */).then(c => wrapFunctional(c.default || c)),
  LocationOnboarding: () => import('../../components/onboarding/LocationOnboarding/LocationOnboarding.vue' /* webpackChunkName: "components/location-onboarding" */).then(c => wrapFunctional(c.default || c)),
  LocationPickerMap: () => import('../../components/onboarding/LocationPickerMap/LocationPickerMap.vue' /* webpackChunkName: "components/location-picker-map" */).then(c => wrapFunctional(c.default || c)),
  LocationAutoComplete: () => import('../../components/onboarding/LocationSelection/LocationAutoComplete.vue' /* webpackChunkName: "components/location-auto-complete" */).then(c => wrapFunctional(c.default || c)),
  LocationSelection: () => import('../../components/onboarding/LocationSelection/LocationSelection.vue' /* webpackChunkName: "components/location-selection" */).then(c => wrapFunctional(c.default || c)),
  LocationSelectionAddress: () => import('../../components/onboarding/LocationSelection/LocationSelectionAddress.vue' /* webpackChunkName: "components/location-selection-address" */).then(c => wrapFunctional(c.default || c)),
  LocationSelectionHub: () => import('../../components/onboarding/LocationSelection/LocationSelectionHub.vue' /* webpackChunkName: "components/location-selection-hub" */).then(c => wrapFunctional(c.default || c)),
  LocationSelectionHubFailure: () => import('../../components/onboarding/LocationSelection/LocationSelectionHubFailure.vue' /* webpackChunkName: "components/location-selection-hub-failure" */).then(c => wrapFunctional(c.default || c)),
  LocationSelectionHubSuccess: () => import('../../components/onboarding/LocationSelection/LocationSelectionHubSuccess.vue' /* webpackChunkName: "components/location-selection-hub-success" */).then(c => wrapFunctional(c.default || c)),
  LocationStaticMap: () => import('../../components/onboarding/LocationStaticMap/LocationStaticMap.vue' /* webpackChunkName: "components/location-static-map" */).then(c => wrapFunctional(c.default || c)),
  OnboardingModalBackButton: () => import('../../components/onboarding/OnboardingModalBackButton/OnboardingModalBackButton.vue' /* webpackChunkName: "components/onboarding-modal-back-button" */).then(c => wrapFunctional(c.default || c)),
  WaitlistSignupForm: () => import('../../components/onboarding/WaitlistSignup/WaitlistSignupForm.vue' /* webpackChunkName: "components/waitlist-signup-form" */).then(c => wrapFunctional(c.default || c)),
  WaitlistSignupFormFull: () => import('../../components/onboarding/WaitlistSignup/WaitlistSignupFormFull.vue' /* webpackChunkName: "components/waitlist-signup-form-full" */).then(c => wrapFunctional(c.default || c)),
  WaitlistSignupSuccessModal: () => import('../../components/onboarding/WaitlistSignup/WaitlistSignupSuccessModal.vue' /* webpackChunkName: "components/waitlist-signup-success-modal" */).then(c => wrapFunctional(c.default || c)),
  AccountTab: () => import('../../components/account/profile-page/AccountTab/AccountTab.vue' /* webpackChunkName: "components/account-tab" */).then(c => wrapFunctional(c.default || c)),
  HelpTab: () => import('../../components/account/profile-page/HelpTab/HelpTab.vue' /* webpackChunkName: "components/help-tab" */).then(c => wrapFunctional(c.default || c)),
  InviteFriendsTab: () => import('../../components/account/profile-page/InviteFriendsTab/InviteFriendsTab.vue' /* webpackChunkName: "components/invite-friends-tab" */).then(c => wrapFunctional(c.default || c)),
  OrderHistory: () => import('../../components/account/profile-page/OrderHistory/OrderHistory.vue' /* webpackChunkName: "components/order-history" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
